import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/api/api.service';
import { LoginResponse } from 'src/app/api/models/user/login-response';
import { LoginStatus } from 'src/app/api/models/user/login-status';
import { User } from 'src/app/api/models/user/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit {
  @ViewChild('video1') video1!: ElementRef<HTMLVideoElement>;
  @ViewChild('video2') video2!: ElementRef<HTMLVideoElement>;

  email: string = '';

  waitingForLogin: boolean = false;
  loginResponseCheck: LoginResponse | null = null;
  loading: boolean = false;
  initLoading: boolean = true;
  loginTimer: any = null;
  isVideo1Visible: boolean = true;
  switchTime = 2; // Time in seconds before the end to trigger switch

  setupProfile: boolean = false;
  user: User | null = null;
  token: string = '';

  @ViewChild('gbutton') gbutton: ElementRef = new ElementRef({});

  constructor(private apiService: ApiService, public authService: AuthService, private router: Router, private messageService: MessageService, private route: ActivatedRoute) {
    this.token = this.route.snapshot.params['token'];
    if (this.token) {
      localStorage.setItem('auth-token', this.token);
      this.apiService.setToken(this.token);
    }

    //get oath2 client_id,response_type,scope,state,redirect_uri from url
    authService.oAuthCheck();

    this.authService.getAccount().subscribe((user: User) => {
      this.initLoading = false;
      this.user = user;
      if (user.alias == null || user.alias == '') {
        this.setupProfile = true;
      } else {
        if (!this.authService.isOauth) {
          this.setupOrContinue();
        }
      }
    }, (error) => {
      this.initLoading = false;
    });
  }


  ngAfterViewInit(): void {
    this.showGoogleLogin();

    // Start the first video
    this.playVideo(this.video1.nativeElement);

    // Set up 'timeupdate' event listeners to switch before the end
    this.video1.nativeElement.addEventListener('timeupdate', () => {
      this.checkAndSwitch(this.video1.nativeElement, this.video2.nativeElement);
    });
    this.video2.nativeElement.addEventListener('timeupdate', () => {
      this.checkAndSwitch(this.video2.nativeElement, this.video1.nativeElement);
    });
  }


  checkAndSwitch(current: HTMLVideoElement, next: HTMLVideoElement) {
    // Calculate time remaining in the current video
    const timeRemaining = current.duration - current.currentTime;
    if (timeRemaining <= this.switchTime) {
      this.fadeToNextVideo(current, next);
    }
  }

  fadeToNextVideo(current: HTMLVideoElement, next: HTMLVideoElement) {
    // Only switch if the other video isn't already playing
    if (!next.paused) return;

    this.isVideo1Visible = !this.isVideo1Visible; // Toggle visibility class
    current.pause();
    next.currentTime = 0; // Reset the next video to the beginning
    this.playVideo(next);
  }

  playVideo(video: HTMLVideoElement) {
    video.muted = true;
    video.play().catch(error => {
      console.error('Auto-play was prevented:', error);
    });
  }

  showGoogleLogin() {
    if (!this.waitingForLogin && !this.initLoading && !this.setupProfile && !this.user) {
      let isLocal = window.location.hostname == "localhost" || window.location.hostname == "";
      //@ts-ignore
      google.accounts.id.initialize({
        client_id: "350823165850-of3dculic5ck2vd9bn6i9s51k3se48kg.apps.googleusercontent.com",
        itp_support: true,
        ux_mode: 'redirect',
        login_uri: isLocal ? "http://localhost/oauth2/token/exchange" : "https://api.display-link.nl/oauth2/token/google",
      });
      //@ts-ignore
      google.accounts.id.renderButton(
        this.gbutton.nativeElement,
        {
          type: "standard", theme: "outline",
          size: "medium", shape: "pill", ux_mode: "redirect",
        }
      );
    } else {
      setTimeout(() => {
        this.showGoogleLogin();
      }, 1000);
    }
  }

  logout() {
    this.authService.logOut();
  }

  oAuthApprove() {
    this.setupOrContinue();
  }

  setCookie(name: string, value: string, days: number) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  login() {
    this.loading = true;
    this.apiService.login('email', this.email).subscribe((loginResponse: LoginResponse) => {
      this.waitingForLogin = true;
      this.loginResponseCheck = loginResponse;
      this.loginCheck();
    }, (error) => {
      this.loading = false;
      this.waitingForLogin = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
    });
  }

  loginCheck() {
    this.loginTimer = setTimeout(() => {
      if (this.loginResponseCheck == null) { return; }
      this.apiService.getLoginStatus(this.loginResponseCheck.checkToken).subscribe((loginStatus: LoginStatus) => {
        if (loginStatus.approved) {
          localStorage.setItem('auth-token', loginStatus.token);
          this.apiService.setToken(loginStatus.token);
          this.authService.getAccount().subscribe((user: User) => {
            this.waitingForLogin = false;
            this.user = user;
            if (user.alias == null || user.alias == '') {
              this.setupProfile = true;
            } else {
              if (!this.authService.isOauth) {
                this.setupOrContinue();
              }
            }
          });
        } else {
          this.loginCheck();
        }
      });
    }, 1000);
  }

  setupOrContinue() {
    this.authService.selectAccount(-1).then(() => {
      if (this.authService.selectedAccountId == null) {
        if (this.authService.isOauth) {
          this.router.navigate(['/setup'], { queryParams: { client_id: this.authService.client_id, response_type: this.authService.response_type, scope: this.authService.scope, state: this.authService.state, redirect_uri: this.authService.redirect_uri } });
        } else {
          this.router.navigate(['/setup']);
        }
        return;
      } else {
        if (this.authService.isOauth) {
          this.apiService.createOath2Token(this.authService.scope, this.authService.client_id).subscribe((response: any) => {
            window.location.href = this.authService.redirect_uri + '?code=' + response.token + '&state=' + this.authService.state;
          });
        } else {
          this.router.navigate([this.authService.getAccountBasePath()]);
        }
      }
    });
  }

  cancelLogin() {
    this.loginResponseCheck = null;
    clearTimeout(this.loginTimer);
    this.waitingForLogin = false;
    this.loading = false;
  }

  saveProfile() {
    this.apiService.updateUser(this.user).subscribe(result => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Profile updated' });
      this.setupOrContinue();
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
    });
  }
}

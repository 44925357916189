import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SetupComponent } from './components/setup/setup.component';
import { UserRole } from './enums/user-role';
import { AuthGuardGuard } from './services/auth-guard.guard';
import { WebDeviceComponent } from './components/web-device/web-device.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'account/:id',
    loadChildren: () => import('./account/account/account.module').then(m => m.AccountModule),
    data: { roles: [UserRole.Account] },
    canActivate: [AuthGuardGuard],
    canActivateChild: [AuthGuardGuard],
  },
  {
    path: 'partner/:id',
    loadChildren: () => import('./partner/partner/partner.module').then(m => m.PartnerModule),
    data: { roles: [UserRole.Partner] },
    canActivate: [AuthGuardGuard],
    canActivateChild: [AuthGuardGuard],
  },
  {
    path: 'oauth2/authorize',
    component: LoginComponent
  },
  {
    path: 'oauth2/token/authorize/:token',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'setup',
    component: SetupComponent
  },
  {
    path: 'onboarding',
    component: OnboardingComponent
  },
  {
    path: 'device',
    component: WebDeviceComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<video #video1  muted="muted" webkit-playsinline="true"
playsinline="true" [class.visible]="isVideo1Visible">
    <source src="https://cdn.display-link.nl/bg-1.mp4" type="video/mp4">
</video>

<video #video2 muted="muted" webkit-playsinline="true"
playsinline="true" [class.visible]="!isVideo1Visible">
    <source src="https://cdn.display-link.nl/bg-2.mp4" type="video/mp4">
</video>

<div class="loginContainer">
    <div class="login overlay" [class.waitingForLogin]="waitingForLogin" style="padding: 0; display: flex; overflow: hidden;">
        <div *ngIf="!initLoading" class="login-image flex">
            <img src="assets/svgs/login.svg" />
        </div>

        <div style="padding: 1em;" *ngIf="!waitingForLogin && !initLoading && !setupProfile && !user">
            <div class="flex flex-col h-full w-full gap-4">
                <div class="h-20 mt-2">
                    <h1 class="text-2xl font-bold" *ngIf="!authService.isOauth">Account Login</h1>
                    <h2 class="text-2xl font-bold" *ngIf="authService.isOauth">
                        Connect to {{authService.scope == 'ifttt' ? 'IFTTT' : 'Other'}}
                    </h2>
                    <div class="text-xs font-thin">Please enter your email address to proceed</div>
                </div>
                <div class="h-25">
                    <div class="flex flex-col gap-2">
                        <label for="username" class="font-thin text-xs">Email address</label>
                        <input style="width: 22em;" placeholder="Enter email address" pInputText id="username" (keyup.enter)="login()" [(ngModel)]="email" />
                    </div>
                </div>
                <div class="flex-auto">
                    <div class="login-buttons flex flex-col items-center">
                        <p-button class="w-full" styleClass="w-full" (click)="login()" label="{{loading ? 'Loading...' : 'Next'}}" />
                        <p-divider align="center" type="solid">
                            <span class="text-xs">Or Login with</span>
                        </p-divider>
                        <div #gbutton class="google-login-button"></div>
                    </div>
                </div>
            </div>
        </div>

        <div style="padding: 1em; width: 96%;" *ngIf="user && authService.isOauth && !initLoading && !setupProfile">
            <h2 *ngIf="authService.isOauth">
                Connect to {{authService.scope == 'ifttt' ? 'IFTTT' : 'Other'}}
            </h2>
            <div class="waiting">
                <span>Hi {{user.alias}},</span>
                <span>Do you want to connect your account to {{authService.scope}}?</span>
                <div>
                    <button class="btn border-1" (click)="logout()">Logout</button>
                    <button class="btn border-1" (click)="oAuthApprove()">Approve connection</button>
                </div>

            </div>
        </div>

        <div style="padding: 1em;" *ngIf="waitingForLogin && !initLoading && !setupProfile">
            <h1 class="text-2xl mt-2 font-bold">Account Login</h1>
            <div class="waiting mt-4">
                <span class="text-sm">We've just sent you an email with a link to access your account.<br />

                If you haven't received the email in your inbox, please check your spam or junk folder as it might have been mistakenly filtered.<br />
                
                Once you find the email, click the provided link to log in seamlessly.</span>
                <div class="email-buttons">
                    <div class="email-that-was-sent">{{email}}</div>
                    <p-button (click)="cancelLogin()" label="Change email" />
                </div>
                <div class="waiting-loader">
                    <p-progress-spinner ariaLabel="loading" [style]="{ width: '30px', height: '30px' }" />
                    <span>Waiting to accept email</span>

                </div>
            </div>
        </div>

        <div style="padding: 1em;" *ngIf="setupProfile">
            <div class="h-20 mt-2">
                <h1 class="text-2xl font-bold">Create Profile Details</h1>
                <div class="text-xs font-thin opacity-25">Enter you credential to create an account</div>
            </div>
            <div class="waiting gap-3">
                <span></span>
                <div class="flex gap-3">
                    <div class="flex flex-col gap-2">
                        <label for="profile_firstname" class="font-thin text-xs">First name</label>
                        <input pInputText type="text" placeholder="First name " [(ngModel)]="user.firstname" id="profile_firstname" />
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="profile_lastname" class="font-thin text-xs">Last name</label>
                        <input pInputText type="text" placeholder="Last name" [(ngModel)]="user.lastname" id="profile_lastname" />
                    </div>
                </div>
                <div class="w-full">
                    <div class="flex flex-col gap-2">
                        <label for="profile_alias" class="font-thin text-xs">Screen name</label>
                        <input class="w-full" pInputText type="text" placeholder="Screen name" [(ngModel)]="user.alias" id="profile_alias" />
                    </div>
                </div>

                <div class="w-full">
                    <p-button class="w-full" styleClass="w-full" (click)="saveProfile()" label="Create profile" />
                </div>
            </div>
        </div>

        <div style="padding: 1em;" class="w-full h-full" *ngIf="initLoading">
            <div class="flex w-full h-full justify-center items-center" style="height: 20em;">
                <p-progress-spinner ariaLabel="loading" />
            </div>
        </div>
    </div>
</div>
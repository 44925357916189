import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { UserRole } from '../enums/user-role';

@Injectable({ providedIn: 'root' })
export class AuthGuardGuard  {
  constructor(private authService: AuthService, private router: Router) { }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean | UrlTree>((resolve, reject) => {
      this.authService.getAccount().subscribe(user => {
        resolve(true);
      }, error => {
        resolve(this.router.parseUrl('/login'));
      });
    });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise<boolean | UrlTree>((resolve, reject) => {
      this.authService.getAccount().subscribe(async user => {
        let id = route.params['id'];
        let role: UserRole = route.data['roles'];
        if(role == UserRole.Partner){
          await this.authService.selectPartner(id);
        }else if(role == UserRole.Account){
          await this.authService.selectAccount(id);
        }

        if (role == UserRole.Partner) {        
            if (this.authService.selectedPartnerId == null) {
              resolve(this.router.parseUrl('/'));
              return;
            }
            if (this.authService.selectedPartnerId == id) {
              resolve(true);
              return;
            }
        } else if (role == UserRole.Account) {
            if (this.authService.selectedAccountId == null) {
              resolve(this.router.parseUrl('/setup'));
              return;
            }
            if (this.authService.selectedAccountId == id) {
              resolve(true);
              return;
            }
        }

        if (role == UserRole.Partner) {
          this.authService.role = UserRole.Partner;
          resolve(this.router.parseUrl(this.authService.getPartnerBasePath()));
          return;
        } else if (role == UserRole.Account) {
          this.authService.role = UserRole.Account;
          resolve(this.router.parseUrl(this.authService.getAccountBasePath()));
          return;
        }
        resolve(false);
      }, error => {
        resolve(this.router.parseUrl('/login'));
      });
    });
  }
}

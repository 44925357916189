<div class="setupContainer">
    <div class="overlay">
        <div class="flex flex-col gap-4">
            <div class="">
                <h1 class="text-2xl font-bold">Create account</h1>
                <div class="text-xs font-thin opacity-50">Choose a unique name for your account. You can pick a new name for it whenever you want. (You can change it later on.)</div>
            </div>
            <div style="padding-top: 1em; padding-bottom: 1em;">
                <div class="flex flex-col gap-2">
                    <label for="account_name" class="font-thin text-xs">Account name</label>
                    <input class="w-full" pInputText type="text" placeholder="Enter your account name" [(ngModel)]="name" id="account_name" />
                </div>
            </div>
            <div>
                <p-button class="w-full" styleClass="w-full" (click)="setup()" label="Create new account" />
            </div>
        </div>
    </div>
</div>
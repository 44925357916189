import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SetupComponent } from './components/setup/setup.component';

import { SharedModule } from './shared/shared/shared.module';

import { AccountModule } from './account/account/account.module';
import { PartnerModule } from './partner/partner/partner.module';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

registerLocaleData(localeNl);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SetupComponent
  ],
  bootstrap: [AppComponent],
  exports: [], 
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AccountModule,
    PartnerModule
  ],
  providers: [
    DynamicDialogRef,
    DialogService,
    MessageService,
    ConfirmationService,
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync()
  ]
})
export class AppModule {
  constructor() {

  }
}

